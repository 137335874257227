<template>
  <v-card width="700px" class="pa-4" :color="darkMode ? '#121212' : ''" style="margin-left: 5%; margin-top: 50px;" elevation="0">
    <v-row>
      <v-col cols="3" class="font-weight-bold pt-1 d-flex align-center">
        Code:
      </v-col>
      <v-col cols="9" class="font-weight-bold pt-1 d-flex align-center">
        <span class="text-h5 font-weight-bold">{{ site.siteCode }}</span>
        <v-btn elevation="0" x-small class="mr-2 ml-8" color="error" outlined @click="deleteSite(item)">DELETE</v-btn>
        <v-btn elevation="0" x-small class="mr-2" color="primary" outlined @click="editSite(item)">EDIT</v-btn>
      </v-col>
      <v-col cols="3" class="font-weight-bold pt-1 d-flex align-center">
        Type:
      </v-col>
      <v-col cols="9" class="font-weight-bold pt-1 d-flex align-center">
        <v-chip small :color="chipColor(siteType)">
          {{ siteType }}
        </v-chip>
      </v-col>
      <v-col cols="3" class="font-weight-bold pt-1 d-flex align-center">
        Status:
      </v-col>
      <v-col cols="9" class="font-weight-bold pt-1 d-flex align-center">
        <v-chip v-if="site.status" small :color="chipColor(site.status.status)">
          {{ site.status.status }}
        </v-chip>
      </v-col>
      <v-col cols="3" class="font-weight-bold pt-1 d-flex align-center">
        Name:
      </v-col>
      <v-col cols="9" class="font-weight-bold pt-1 d-flex align-center">
        <span class="text-h6 font-weight-bold">
          {{ site.name }}
        </span>
      </v-col>
      <v-col cols="3" class="font-weight-bold pt-1 d-flex align-center">
        Address:
      </v-col>
      <v-col cols="9" class="pt-1 d-flex align-center">
        <span>
          {{ site.address }}
        </span>
      </v-col>
      <v-col cols="3" class="font-weight-bold pt-1 d-flex align-center">
        Description:
      </v-col>
      <v-col cols="9" class="pt-1 d-flex align-center">
        <span>
          {{ site.notes }}
        </span>
      </v-col>
      <v-col cols="3" class="font-weight-bold pt-1 d-flex align-center">
        Land Lord:
      </v-col>
      <v-col cols="9" class="pt-1 d-flex align-center">
        <span>
          to-do
        </span>
      </v-col>
      <v-col cols="3" class="font-weight-bold pt-1 d-flex align-center">
        Site Contact:
      </v-col>
      <v-col cols="9" class="pt-1 d-flex align-center">
        <span>
          to-do
        </span>
      </v-col>
      <v-col cols="3" class="font-weight-bold pt-1 d-flex align-center">
        Contact Phone:
      </v-col>
      <v-col cols="9" class="pt-1 d-flex align-center">
        <span>
          to-do
        </span>
      </v-col>
      <v-col cols="3" class="font-weight-bold pt-1 d-flex align-center">
        Contact Email:
      </v-col>
      <v-col cols="9" class="pt-1 d-flex align-center">
        <span>
          to-do
        </span>
      </v-col>
      <v-col cols="3" class="font-weight-bold pt-1 d-flex align-center">
        Hauling Contractor:
      </v-col>
      <v-col cols="9" class="pt-1 d-flex align-center">
        <span>
          to-do
        </span>
      </v-col>
    </v-row>
  </v-card>

</template>

<script>
// import { ref } from 'vue'
import { mapState } from 'vuex'// import { mapGetters, mapState, mapMutations } from 'vuex'
import { chipColorClass } from '@/helpers/StylingHelper.js'

export default {
  components: {
  },
  data: () => ({
    activeTab: 0,
    siteId: null
  }),
  computed: {
    ...mapState({
      darkMode: (state) => state.app.isDarkMode
    }),
    filters () {
      const filters = []
      if (this.siteId) filters.push({ name: 'Id', values: [this.siteId] })
      return filters
    },
    siteType () {
      if (!this.site) return '-'
      if (this.site.isDonor && this.site.IsReceiver) return 'EXPORTER & IMPORTER'
      if (this.site.isDonor) return 'EXPORTER'
      if (this.site.IsReceiver) return 'IMPORTER'
      return null
    }
  },
  props: {
    site: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    chipColor (name) {
      return chipColorClass(name)
    },
    deleteSite (site) {
      console.log(site)
    },
    editSite (site) {
      console.log(site)
    }
  }

}

</script>
<style>
.v-tabs.theme--dark div.v-tabs-bar {
  background-color: #121212 !important;
}
</style>
