<template>
   <site-template @siteChanged="(value) => siteChanged(value)" />
</template>
<script>

// Components
import SiteTemplate from '@/components/sites/SiteTemplate.vue'

export default {
  name: 'SiteView',
  components: {
    SiteTemplate
  },
  methods: {
    async siteChanged (value) {
      this.$emit('updateModuleNameExtension', value)
    }
  }
}

</script>
