<template>
    <v-container class="unreactive-margins">
     <v-data-table
       :headers="headers"
       :items="documents"
     >
      <template #[`item.references`]="{ item }">
        <v-chip color="success" class="ma-1" small outlined v-for="site in item.sites" :key="`doc-${item.id}-site-${site.id}`"><span class="font-weight-bold">{{ site.siteCode }}</span></v-chip>
        <v-chip color="info" class="ma-1" small outlined v-for="segment in item.segments" :key="`doc-${item.id}-segment-${segment.id}`"><span class="font-weight-bold">{{ segment.code }}</span></v-chip>
        <v-chip color="warning" class="ma-1" small outlined v-for="segmentDepth in item.segmentDepths" :key="`doc-${item.id}-segment-depth-${segmentDepth.id}`"><span class="font-weight-bold">{{ segmentDepth.code }}</span></v-chip>
      </template>
      <template #[`item.uploadedBy`]="{ item }">
        {{ `${item.uploadedBy.lastName}, ${item.uploadedBy.firstName}` }}
      </template>
      <template #[`item.uploadedOn`]="{ item }">
        {{ formatDate(item.uploadedOn) }}
      </template>
      <template #[`item.actions`]="{ item }">
        <v-btn elevation="0" x-small class="mr-1" color="info" outlined @click="downloadDocument(item)">Download</v-btn>
      </template>
     </v-data-table>
   </v-container>
 </template>

<script>
import { chipColorClass } from '@/helpers/StylingHelper.js'
import { createPaginationObject } from '@/helpers/PaginationHelper.js'
import { dateFromUTC } from '@/helpers/FormatHelper.js'

export default {
  props: {
    site: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    documents: [],
    loadingDocuments: {
      loading: false,
      promise: null
    }
  }),
  computed: {
    headers () {
      const headers = []
      headers.push({ text: 'Title', value: 'title' })
      headers.push({ text: 'References', value: 'references' })
      headers.push({ text: 'Uploaded By', value: 'uploadedBy' })
      headers.push({ text: 'Uploaded On', value: 'uploadedOn' })
      headers.push({ text: 'Actions', value: 'actions', align: 'end' })
      return headers
    },
    filters () {
      const filters = []
      if (this.site && this.site.id) filters.push({ name: 'SiteId', values: [this.site.id] })
      return filters
    }
  },
  watch: {
    site: {
      handler (value) {
        if (value && value.id) this.loadDocuments()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async loadDocuments () {
      const search = createPaginationObject(null, null, this.filters)
      let siteRes = null
      // pull up the site
      if (!this.loadingDocuments.loading) {
        this.loadingDocuments.loading = true
        this.loadingDocuments.promise = this.$api.documents.read(search)
        siteRes = await this.loadingDocuments.promise
        this.loadingDocuments.loading = false
      } else {
        siteRes = await this.loadingDocuments.promise
        this.loadingDocuments.loading = false
      }
      this.documents = siteRes.documents.map((document) => {
        return {
          ...document
        }
      })
    },
    downloadDocument (doc) {
      this.$api.documents.downloadDocument(doc.id, doc.originalName, doc.extension)
    },
    chipColor (name) {
      return chipColorClass(name)
    },
    siteType (site) {
      if (site.isDonor && site.IsReceiver) return 'Hybrid'
      if (site.isDonor) return 'Exporter'
      if (site.IsReceiver) return 'Importer'
      return null
    },
    formatDate (date) {
      return dateFromUTC(date)
    }
  }
}
</script>
 <style>
   .container.unreactive-margins {
     margin-left: 0px !important;
     margin-right: 0px !important;
     width: 100% !important;
     max-width: 100% !important;
   }
 </style>
