<template>
  <div>
    <document-upload-form :site="site" ref="documentUploadForm" :returnFile="true" :multiple="true" @success="(res) => documentUploaded(res)" />
    <v-row class="mx-2 mt-1">
      <v-col cols="9">
        <v-tabs v-model="activeTab">
          <v-tab value='info'>Info</v-tab>
          <v-tab value='map'>Map</v-tab>
          <v-tab value='documents'>Documents</v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="3" class="text-right justify-end d-flex align-center">
        <v-btn v-if="activeTab == 2" elevation="0" outlined prepend variant="outlined" @click="refreshDocuments" class="mr-2"><v-icon>mdi-refresh</v-icon>Refresh</v-btn>
        <v-btn v-if="activeTab == 2" elevation="0" outlined prepend variant="outlined" @click="openDocumentUploadForm"><v-icon>mdi-plus</v-icon>New Document</v-btn>
      </v-col>
    </v-row>

    <v-window v-model="activeTab">
      <v-window-item :value="0">
        <site-info :site="site" />
      </v-window-item>

      <v-window-item :value="1">
        window 2
        <!--<site-map :selected-site="site" />-->
      </v-window-item>

      <v-window-item :value="2">
        <documents-table :site="site" ref="documentsTable" />
      </v-window-item>

      <v-window-item :value="3">
        <!--fake window for proper slide transitions. do not remove this or the transition between first and last tab will look wrong-->
      </v-window-item>
    </v-window>
  </div>

</template>

<script>
// import { ref } from 'vue'
import { createPaginationObject } from '@/helpers/PaginationHelper.js'
import { mapState } from 'vuex'// import { mapGetters, mapState, mapMutations } from 'vuex'
// import SiteMap from '@/components/sites/SiteMap.vue'
import SiteInfo from '@/components/sites/SiteInfo.vue'
import DocumentsTable from '@/components/documents/DocumentsTable.vue'
import DocumentUploadForm from '@/components/documents/DocumentUploadForm.vue'

export default {
  components: {
    DocumentsTable,
    SiteInfo,
    DocumentUploadForm
    // SiteMap
  },
  data: () => ({
    activeTab: 0,
    siteId: null,
    site: {},
    documentUploadDialog: false
  }),
  computed: {
    ...mapState({
      darkMode: (state) => state.app.isDarkMode,
      selectedSiteId: (state) => state.site.selectedSiteId
    }),
    filters () {
      const filters = []
      if (this.siteId) filters.push({ name: 'SiteId', values: [this.siteId] })
      return filters
    }
  },
  watch: {
    activeTab: {
      handler (value) {
        if (value === 2) this.refreshDocuments()
      },
      deep: true,
      immediate: true
    },
    selectedSiteId: {
      handler (value) {
        this.$store.dispatch('action/refreshActionCount')
        this.siteId = value
        this.loadSite()
        if (this.activeTab === 2) this.refreshDocuments()
      },
      immediate: true
    }
  },
  created () {
  },
  methods: {
    async processQuery () {
      if (this.$route.query.siteId) {
        this.siteId = this.$route.query.siteId
        // const query = { ...this.$route.query }
        // delete query.siteId
        // this.$router.replace({ query })
      } else this.siteId = null
    },
    async loadSite () {
      // search object is universal, as all APIs accept searching by "SiteId" filter
      const search = createPaginationObject(null, null, this.filters)
      // pull up the site
      const siteRes = await this.$api.sites.read(search)
      this.site = siteRes.sites.map((site) => {
        return {
          ...site,
          address: site.address ? `${site.address.addressLine1}, ${site.address.city.name}, ${site.address.city.province.shortName} ${site.address.postalCode}` : '-',
          geoLocation: site.address ? `${site.address.latitude}, ${site.address.longitude}` : '-',
          segments: []
        }
      })[0]
      this.$emit('siteChanged', `${this.site.name}`)
      // populate the site with segments
      const segmentsRes = await this.$api.segments.read(search)
      this.site.segments = segmentsRes.segments.map((segment) => {
        return {
          ...segment,
          expanded: false,
          segmentDepths: []
        }
      })
      // populate all segments with their depths
      const depthRes = await this.$api.segmentDepths.read(search)
      this.site.segments.forEach(segment => {
        segment.segmentDepths = depthRes.segmentDepths.filter((segmentDepth) => {
          return segmentDepth.segmentId === segment.id
        })
      })
    },
    openDocumentUploadForm () {
      this.$refs.documentUploadForm.openDialog()
    },
    documentUploaded (res) {
      this.refreshDocuments()
    },
    async refreshDocuments () {
      await this.$nextTick()
      if (this.$refs.documentsTable) this.$refs.documentsTable.loadDocuments()
    }
  }

}

</script>
<style>
.v-tabs.theme--dark div.v-tabs-bar {
  background-color: #121212 !important;
}
</style>
