<template>
  <v-dialog
    v-model="dialogOpen"
    width="800"
    content-class="custom-underlight"
  >
    <v-card
        :class="`${ dragover && darkMode ? 'grey darken-3' : ''} ${ dragover && !darkMode ? 'grey lighten-2' : ''} custom-underlight`"
        @drop.prevent="onDrop($event)"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragleave.prevent="dragover = false"
    >
    <v-card-title>
      <span class="text-h4">Upload Document for {{ site ? site.name : 'Site' }}</span>
    </v-card-title>
      <v-card-text>
        <template v-if="uploading">
          <v-row>
            <v-col class="d-flex justify-center pb-0 mt-4">
              <v-progress-circular
                color="gray"
                :size="80"
                :width="10"
                :value="!uploadProgress || !uploadProgress.total || !uploadProgress.loaded ? 0 : uploadProgress.loaded / uploadProgress.total * 100"
              >
                <span class="font-weight-bold">
                  {{ !uploadProgress || !uploadProgress.total || !uploadProgress.loaded ? 0 : uploadProgress.loaded / uploadProgress.total * 100 }}%
                </span>
              </v-progress-circular>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-img
            v-if="existingImagePreview != null && existingImagePreview.mimeType != null"
            :src="'data:' + existingImagePreview.mimeType + ';base64,' + existingImagePreview.file"
            :max-height="imagePreviewMaxHeight"
            contain
          />
          <a
            v-else-if="uploadedFiles.length == 0"
            @click="clicked()"
          >
            <v-row
              class="d-flex flex-column"
              dense
              align="center"
              justify="center"
            >
              <v-icon
                :class="[dragover ? 'mt-2, mb-6' : 'mt-5']"
                size="60"
              >
                mdi-cloud-upload
              </v-icon>
              <p>
                Drop your file{{ multiple ? '(s)' : '' }} here, or click to select {{ multiple ? 'them' : 'it' }}.
              </p>
            </v-row>
          </a>
          <template v-else-if="returnFile == true">
            <v-virtual-scroll
              :items="uploadedFiles"
              height="150"
              item-height="50"
            >
              <template #default="{ item }">
                <v-list-item :key="item.name">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }}
                      <span class="ml-3 text--secondary">
                        {{ item.size }} bytes</span>
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn
                      icon
                      @click.stop="removeFile(item.name)"
                    >
                      <v-icon> mdi-close-circle </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>

                <v-divider />
              </template>
            </v-virtual-scroll>
          </template>
        </template>
      </v-card-text>
      <v-card-actions v-if="!suppressActions">
        <v-spacer />

        <v-btn
          icon
          @click="removeFile()"
        >
          <v-icon id="close-button">
            mdi-close
          </v-icon>
        </v-btn>

        <v-btn
          v-if="!hideUpload"
          icon
          @click="upload"
        >
          <v-icon id="upload-button">
            mdi-upload
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'// import { mapGetters, mapState, mapMutations } from 'vuex'
export default {
  data: () => ({
    dragover: false,
    uploading: false,
    uploadProgress: null,
    uploadedFiles: [],
    tempFile: null,
    existingImagePreview: null,
    dialogOpen: false,
    supportedFileFormats: [
      {
        type: 'image',
        extension: 'jpg',
        mimeType: 'image/jpeg'
      },
      {
        type: 'image',
        extension: 'png',
        mimeType: 'image/png'
      },
      {
        type: 'file',
        extension: 'doc',
        mimeType: 'application/msword'
      },
      {
        type: 'file',
        extension: 'docx',
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      },
      {
        type: 'file',
        extension: 'pdf',
        mimeType: 'application/pdf'
      }
    ]
  }),
  props: {
    site: {
      type: Object,
      default: () => {}
    },
    returnFile: {
      type: Boolean,
      default: false
    },
    suppressActions: {
      type: Boolean,
      default: false
    },
    hideUpload: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      darkMode: (state) => state.app.isDarkMode
    })
  },
  created () {
  },
  watch: {
    // eslint-disable-next-line quote-props
  },
  methods: {
    openDialog () {
      this.dialogOpen = true
    },
    onDrop (e) {
      this.dragover = false
      // Check if there are already uploaded files
      if (this.uploadedFiles.length > 0) this.uploadedFiles = []
      const files = Array.from(e.dataTransfer.files)
      this.processFiles(files)
    },
    clicked () {
      const input = document.createElement('input')
      input.type = 'file'
      input.onchange = () => {
        const files = Array.from(input.files)
        this.processFiles(files)
      }
      input.click()
    },
    processFiles (files) {
      let fileExtensions = ''
      let first = true
      this.supportedFileFormats.forEach((sff, index, self) => {
        if (!first) {
          if (self.length === index + 1) fileExtensions += ` or .${sff.extension}`
          else fileExtensions += `, .${sff.extension}`
        } else {
          fileExtensions += `.${sff.extension}`
        }
        first = false
      })
      if (!this.multiple && files.length > 1) {
        this.$store.dispatch(
          'app/showError', 'Can only upload one file at a time'
        )
        // this.$bus.$emit('showError', 'Can only upload one file at a time')
      } else if (files.findIndex((f) => this.supportedFileFormats.findIndex((sff) => sff.mimeType === f.type) === -1) > -1) {
        this.$store.dispatch(
          'app/showError', `Only ${fileExtensions} files are supported`
        )
        // this.$bus.$emit('showError', `Only ${fileExtensions} files are supported`)
      } else {
        files.forEach((element) => this.uploadedFiles.push(element))
        if (this.returnFile) this.$emit('filesAttached', this.uploadedFiles)
        else this.upload()
      }
    },
    async upload () {
      this.uploading = true
      let response = null
      if (this.multiple) {
        response = await this.$api.documents.uploadMultiple('site', this.site.id, this.uploadedFiles, (event) => {
          this.uploadProgress = event
        })
      } else {
        response = await this.$api.documents.upload('site', this.site.id, this.uploadedFiles[0], (event) => {
          this.uploadProgress = event
        })
      }
      if (response === true || (!this.multiple && response.id != null) || (this.multiple && response && response.length && !response.some(x => x == null))) {
        this.$emit('success', response)
        // this.$bus.$emit('showSuccess', `File${this.uploadedFiles.length > 1 ? 's' : ''} Uploaded`)
        this.$store.dispatch(
          'app/showSuccess', `File${this.uploadedFiles.length > 1 ? 's' : ''} Uploaded`
        )
        this.uploadedFiles = []
        this.dialogOpen = false
      } else if (this.multiple) {
        const failedCount = response.filter(x => x == null).length
        let successfulUploads = ''
        if (response.length < failedCount) {
          successfulUploads = ` The following file${(response.length - failedCount) > 1 ? 's were' : ' was'} uploaded successfully: `
          let first = true
          response.filter(file => file != null).forEach(file => {
            if (first) successfulUploads += ', '
            successfulUploads += file.originalName
            first = false
          })
        }
        this.$store.dispatch(
          'app/showError', `Failed to upload ${failedCount} File${failedCount > 1 ? 's' : ''}.${successfulUploads}`
        )
        // this.$bus.$emit('showError', `Failed to upload ${failedCount} File${failedCount > 1 ? 's' : ''}.${successfulUploads}`)
      } else this.$bus.$emit('showError', 'Failed to upload File')
      this.uploading = false
    }
  }
}
</script>
<style>
  .theme--dark.custom-underlight {
    box-shadow: rgba(255, 255, 255, 0.2) 0px 3px 1px -2px, rgba(255, 255, 255, 0.14) 0px 2px 2px 0px, rgba(255, 255, 255, 0.12) 0px 1px 5px 0px !important;
  }
  .theme--dark .v-dialog__content .custom-underlight {
    box-shadow: rgba(255, 255, 255, 0.2) 0px 5px 6px -1px, rgba(255, 255, 255, 0.14) 0px 5px 4px 0px, rgba(255, 255, 255, 0.12) 0px 1px 10px 5px !important;

  }
</style>
